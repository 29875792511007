import React, { useState } from 'react';
import axios from "axios";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

function ParamsApp() {
    const navigate = useNavigate();
    const [selectedFeature, setSelectedFeature] = useState({
        pression: false,
        energie_cumulee: false,
    });
    const handleCheckboxChange = (e, type) => {
        const isChecked = e.target.checked;
        setSelectedFeature((prevState) => ({
            ...prevState, [type]: isChecked
        }));
    };


    const handelSubmit = async (e) => {
        toast.success("choix validé")
        setTimeout(() => {
            navigate("/")
        }, 3000);

        try {
            const data = await axios
                .post(process.env.REACT_APP_BACKEND_IP + ':'
                    + process.env.REACT_APP_BACKEND_PORT + '/parametreApp/saveParametreApp/'
                    + selectedFeature.energie_cumulee + '/' + selectedFeature.pression);
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        async function fetchData() {
            let dataParametreApp;
            try {
                dataParametreApp = await axios.get(process.env.REACT_APP_BACKEND_IP
                    + ':' + process.env.REACT_APP_BACKEND_PORT
                    + '/parametreApp/getDataParamsApp')
            } catch (error) {
                console.log(error)
            }
            if (dataParametreApp.data != null) {
                setSelectedFeature(dataParametreApp.data)
            };
        }
        fetchData();
    }, [])

    return (
        <>
            <div className="page">
                <div className="page-main">
                    <div className="main-container container-fluid">
                        <div className="page-header">
                            <div>
                                <h1 className="page-title">Paramètres Application (ADMIN)</h1>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header border-bottom">
                                <h3 className="card-title">Sélectionnez options à afficher</h3>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    <label className="form-label col-md-3 col-lg-3 col-xl-2">
                                        Pression
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-10">
                                        <input
                                            id="checkedDangerSwitchP"
                                            name="switchP"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "pression")}
                                            checked={(selectedFeature.pression === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchP"
                                            className="label-danger"
                                        ></label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <label className="form-label col-md-3 col-lg-3 col-xl-2">
                                        Energie Cumulée
                                    </label>
                                    <div className="material-switch col-md-1 col-lg-1 col-xl-10">
                                        <input
                                            id="checkedDangerSwitchE"
                                            name="switchE"
                                            type="checkbox"
                                            onChange={(e) => handleCheckboxChange(e, "energie_cumulee")}
                                            checked={(selectedFeature.energie_cumulee === true) ? true : false}
                                        />
                                        <label
                                            for="checkedDangerSwitchE"
                                            className="label-danger"
                                        ></label>
                                    </div>

                                </div>
                                <div className='row'>
                                    <div className='col-md-2 mt-5 col-lg-2 col-xl-2'>
                                        <button className="btn btn-outline-primary btn-block" type="submit" onClick={(e) => { handelSubmit(e); }}>
                                            <i className="fa fa-bar-chart me-2"></i>Valider
                                        </button>
                                        <ToastContainer
                                            className="toast-container"
                                            autoClose={2000}
                                            closeButton={false}
                                            theme={"colored"}
                                            icon={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ParamsApp
