import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";


const ResetPasswordEmail = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/forgot-password`, { email: email });        
          console.log('Response:', response.data);
          navigate('/check-email', { state: { email } });
          toast.success('Consultez votre courrier électronique pour obtenir des instructions sur la réinitialisation de votre mot de passe.');
      } catch (error) {
          if (error.response && error.response.status === 500) {
              toast.error('Email introuvable');
          } else {
              toast.error(error.response.data);
          }
      }
  };

  return (
    <div className="page-register">
      <div className="container-reset">
        <h1 className="heading-reset">Réinitialisation mot de passe</h1>
        <p className="text-reset">
        Vous avez perdu votre mot de passe ? Veuillez saisir votre adresse électronique. Vous recevrez un lien pour créer un nouveau mot de passe par courriel.        </p>
        <form onSubmit={handleSubmit} className="form-reset">
          <label className="label-reset">
            Email *
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="input-reset"
            />
          </label>
          <button type="submit" className="button-reset">Réinitialiser mot de passe</button>
        </form>
      </div>
      <ToastContainer
          className="toast-container"
          autoClose={2000}
          closeButton={false}
          theme={"colored"}
          icon={true}
      />
      </div>
  );
};

export default ResetPasswordEmail;
