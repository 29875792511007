import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Chart from "react-apexcharts";
import Sidebar from "../components/Sidebar";
import SelectBox from "../components/SelectBox";
import SelectOne from "../components/SelectOne";
import DatePicker from "../components/DatePicker";
import TimePicker from "../components/TimePicker";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Rapports() {
    const lignes = [
        {
            label: "Tous",
            value: "all",
        },
        {
            label: "L1",
            value: "l1",
        },
        {
            label: "L2",
            value: "l2",
        },
        {
            label: "L3",
            value: "l3",
        },
    ];
    const RmsTypes = [
        {
          label: "Energie",
          value: "energy"
        },
        {
          label: "Tension",
          value: "tension"
        },
        {
          label: "Courant",
          value: "courant"
        },
        {
            label: "Facteur de puissance",
            value: "cosphi"
          },
        {
          label: "Tension Composée",
          value: "tensionC"
        },
        {
          label: "Puissance",
          value: "puissance"
        },
        {
          label: "Puissance  Apparente",
          value: "puissanceA"
        },
        {
          label: "Puissance Réactive",
          value: "puissanceR"
        },
       
      ];
    const [rms , setRms] = useState([])
    const [machinesList, setMachinesList] = useState([]);
    const [ligne, setLigne] = useState([]);
    const [showCard, setShowCard] = useState(false);
    const [dateEnregistrement, setDateEnregistrement] = useState({
        debut: "",
        fin: "",
    });
    const [min , setMin] = useState("")
    const [max , setMax] = useState("")
    const [selectedTime, setSelectedTime] = useState({
        timeDebut: "",
        timeFin: "",
    });

    const [selectedEntregistrement, setSelectedEnregistrement] = useState({
        lignes: "",
        rms: "",
        machine: "",
        annee: "",
        mois: "",
        jour: "",
        annee1: "",
        mois1: "",
        jour1: "",
        timeDebut: "",
        timeFin: "",
    });
    const [lineChartData, setLineChartData] = useState("");
    const [chartTimes, setChartTimes] = useState("");
    const [sumEnergy, setSumEnergy] = useState("");
    const [array, setArray] = useState([])

    const handleCard = () => {
        setShowCard(true);
    };
    let defaultValue = "";
    const selectAllOption = { label: "Sélectionnez tout", value: "*" };
    const options = [];
    function setOptions() {
        const listMachines = JSON.parse(localStorage.getItem("machines"));
        Object.keys(listMachines).map((index) => {
            var details = {
                value: listMachines[index].machine_id,
                label: listMachines[index].machine_name,
            };
            options.push(details);
        });
    }
    setOptions();
    const [selected, setSelected] = useState({
        machines: [],
        annee: "",
        mois: "",
        jour: "",
    });

    const initialVisibleOptions =
        options.length === defaultValue?.length
            ? options
            : [selectAllOption, ...options];
    const [availableOptions, setAvailableOptions] = useState(
        initialVisibleOptions
    );
    const [selectedValues, setSelectedValues] = useState(defaultValue);
    const [selectedVal, setSelectedVal] = useState(selected.machines);
    const [selectedDate, setSelectedDate] = useState(null);
    const handleChange = (newSelectedOptions) => {
        const selectAllIsSelected = !!newSelectedOptions.find(
            (o) => o.value === selectAllOption.value
        );

        const newComponentState = selectAllIsSelected
            ? {
                selectedValues: options,
                availableOptions: [],
            }
            : {
                selectedValues: newSelectedOptions,
                availableOptions: initialVisibleOptions,
            };

        setSelectedValues(newComponentState.selectedValues);
        setAvailableOptions(newComponentState.availableOptions);
        // this.onChange(newComponentState.selectedValues);
    };
    const handleTypesChange = (event, type) => {
        setSelectedEnregistrement((prevState) => ({
            ...prevState,
            [type]: event.value,
        }));
    };
    const handleDateChange = (date) => {
        var datee = new Date(date);
        var day = datee.getDate();
        var month = datee.getMonth() + 1;
        var year = datee.getFullYear();

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }

        setSelectedDate(datee);
        setSelected((prevState) => ({
            ...prevState,
            annee: year,
            mois: month,
            jour: day,
        }));
    };
    const handleDateEntregistrementChange = (date, type) => {
        var datee = new Date(date);
        var day = datee.getDate();
        var month = datee.getMonth() + 1;
        var year = datee.getFullYear();

        if (day < 10) {
            day = "0" + day;
        }
        if (month < 10) {
            month = "0" + month;
        }

        var new_date = year + '-' + month + '-' + day
        var new_date2 = month + '/' + day + '/' + year 
        setDateEnregistrement(prevState => ({ ...prevState, [type]: datee }));
        setSelectedEnregistrement(prevState => ({ ...prevState, [type]: new_date }));
        setSelectedEnregistrement(prevState => ({ ...prevState, ['date_'+type]: new_date2 }));
    };
    const handleTimeChange = (date, type) => {
        var time = new Date(date);
        var hour = time.getHours();
        var minutes = time.getMinutes();
        if (hour < 10) {
            hour = "0" + hour;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        var new_time = hour + ":" + minutes;
        setSelectedTime((prevState) => ({ ...prevState, [type]: time }));
        setSelectedEnregistrement((prevState) => ({
            ...prevState,
            [type]: new_time,
        }));
    };
    const ChercherSubmit = async (e) => {
        let chartData;
        var time_debut = "";
      var time_fin = "";
      try {
        if(selectedEntregistrement.timeDebut==""){
        time_debut = "00:00";
      }else {
        time_debut = selectedEntregistrement.timeDebut;
      }
      if(selectedEntregistrement.timeFin==""){
        var time = new Date()
        var hour = time.getHours();
        var minutes = time.getMinutes()-1;
        if (hour < 10) {
          hour = "0" + hour
        }
        if (minutes < 10) {
          minutes = "0" + minutes
        }
        var new_time = hour + ':' + minutes
        time_fin  = new_time;
      }
      else {
        time_fin = selectedEntregistrement.timeFin;
      }
      const response = await axios.get(process.env.REACT_APP_BACKEND_IP
        + ':' + process.env.REACT_APP_BACKEND_PORT
        + '/historique/checkDates/'
        + selectedEntregistrement.date_debut + '/'
        + time_debut + '/'
        + selectedEntregistrement.date_fin + '/'
        + time_fin
      );
      if (response.status === 200) {
        
       if (Object.keys(selectedEntregistrement.lignes).length !== 0 && Object.keys(selectedEntregistrement.machine).length !== 0 && dateEnregistrement !== null) {
        if ( selectedEntregistrement.timeDebut !== "" && selectedEntregistrement.timeFin !== "") {
            try {
                chartData = await axios.get(
                    process.env.REACT_APP_BACKEND_IP +
                    ":" +
                    process.env.REACT_APP_BACKEND_PORT +
                    "/rapport/enregistrement_time_chart/" +
                    selectedEntregistrement.machine + "/" + selectedEntregistrement.rms + "/" +
                    selectedEntregistrement.lignes + "/" + selectedEntregistrement.debut + "/" +
                    selectedEntregistrement.timeDebut + "/" + selectedEntregistrement.fin + "/" +
                    selectedEntregistrement.timeFin
                );
                setLineChartData(chartData.data.data_to_return.chart);
                setChartTimes(chartData.data.data_to_return.times);
                setSumEnergy(chartData.data.data_to_return.sum_energy);
            } catch (error) {
                console.log(error);
            }
            handleCard();
        } else {
                try {
                    chartData = await axios.get(
                        process.env.REACT_APP_BACKEND_IP +
                        ":" +
                        process.env.REACT_APP_BACKEND_PORT +
                        "/rapport/enregistrement_history_chart/" +
                        selectedEntregistrement.machine +
                        "/" +
                        selectedEntregistrement.rms +
                        "/" +
                        selectedEntregistrement.lignes +
                        "/" +
                        selectedEntregistrement.debut +
                        "/" +
                        selectedEntregistrement.fin
                    );
                    setLineChartData(chartData.data.data_to_return.chart);
                    setChartTimes(chartData.data.data_to_return.times);
                    setSumEnergy(chartData.data.data_to_return.sum_energy);
                    setMin(chartData.data.data_to_return.min);
                    setMax(chartData.data.data_to_return.max)
                    setArray("")
                } catch (error) {
                    console.log(error);
                }
                handleCard();
            
        }
       }else {
        console.log("data unvailable")
       }
    }
} catch (error) {
    if (error.response && error.response.status === 400) {
      const response = error.response.data;
      // Handle validation errors
      if (response.date1IsBeforedate2 === false) {
        toast.error("Le temps de début doit être antérieur au temps de fin");
      } 
      else if (response.date1IsBeforeNow === false) {
        toast.error("Le temps de début doit être antérieur au temps actuelle");
      } else if (response.date2IsBeforeNow === false) {
        toast.error("Le temps de fin doit être antérieur au temps actuelle");
      } else {
        toast.error("Invalid dates provided.");
      }
    } else {
      console.log(error);
      toast.error("Une erreur s'est produite lors de la vérification des dates");
    }
  }  
    };
    useEffect(() => {
        setMachinesList(JSON.parse(localStorage.getItem("machines")));
        setLigne(lignes);
        setRms(RmsTypes)
        setSelectedVal(
            Object.keys(selectedValues).map(
                (element) => selectedValues[element].value
            )
        );
    }, [selectedValues, lineChartData, chartTimes, selected]);
    const dateFormat = (format) => {
		if (format != undefined) {
			const date = new Date(format);

			//extract the parts of the date
			let day = date.getDate();
			let month = date.getMonth() + 1;
			if (month < 10) {
				month = "0" + month;
			}
			if (day < 10) {
				day = "0" + day;
			}
			const year = date.getFullYear();
			let string = date.toISOString();
			let time = string.match(/\d\d:\d\d/);

			return day + "-" + month + "-" + year + " " + time;
		}

	}
    const ChartCard = () => {
        return (
            <>
                <div className="card">
                {Object.keys(array).length > 0 && (
                    <div>
                    {Object.keys(array).some(index => !["E1", "E2", "E3", "Total"].includes(array[index][0])) && (
                        <div>
                        <div className="card-header border-bottom">
                            <h3 className="card-title">Min & Max pour chaque ligne</h3>
                        </div>
                        <div className="table-responsive export-table">
                            <table className="table table-striped table-bordered text-nowrap key-buttons border-bottom  w-100">
                            <thead>
                                <tr>
                                <th className="border-bottom-0">Voie</th>
                                <th className="border-bottom-0">Min</th>
                                <th className="border-bottom-0">Max</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(array)
                                .filter(index => !["E1", "E2", "E3", "Total"].includes(array[index][0]))
                                .map(index => (
                                    <tr key={index}>
                                    <td>{array[index][0]}</td>
                                    <td>{min[index]}</td>
                                    <td>{max[index]}</td>
                                    </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        </div>
                    )}

                <div className="card-header border-bottom">
                    <h3 className="card-title">{sumEnergy ? `Total énergie : ${sumEnergy} Kwh` : "Données au Curseur"}</h3>
                </div>

                    <div className="table-responsive export-table">
                        <table className="table table-striped table-bordered text-nowrap key-buttons border-bottom  w-100">
                        <thead>
                            <tr>
                            <th className="border-bottom-0">Voie</th>
                            <th className="border-bottom-0">Valeur</th>
                            <th className="border-bottom-0">Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(array).map(index => (
                            <tr key={index}>
                                <td>{array[index][0]}</td>
                                <td>{array[index][1]}</td>
                                <td>{dateFormat(array[index][2])}</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    </div>
                )}


                                    
                    <Chart
                        options={{
                            chart: {
                                id: "energy_enregistre",
                                stacked: false,
                            
                                zoom: {
                                    type: 'x',
                                    enabled: true,
                                    autoScaleYaxis: true
                                  },
                                animations : {
                                    enabled : false 
                                },
                                toolbar: {
                                    show: false
                                  },
                                  fill: {
                                    type: 'gradient',
                                    gradient: {
                                      shadeIntensity: 1,
                                      inverseColors: false,
                                      opacityFrom: 0.5,
                                      opacityTo: 0,
                                      stops: [0, 90, 100]
                                    },
                                  },
                                events: {
                                    click: function (event, chartContext, config) {
                                        if (selectedEntregistrement.rms == "puissance") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['P1', 'P2', 'P3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['P1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['P2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['P3'];
                                            }
                                          }
                
                                          if (selectedEntregistrement.rms == "energy") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['E1', 'E2', 'E3', 'Total'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['E1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['E2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['E3'];
                                            }
                                          }
                
                                          if (selectedEntregistrement.rms == "tension") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['V1', 'V2', 'V3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['V1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['V2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['V3'];
                                            }
                                          }
                
                
                                          if (selectedEntregistrement.rms == "tensionC") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['U1', 'U2', 'U3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['U1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['U2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['U3'];
                                            }
                                          }
                
                
                
                                          if (selectedEntregistrement.rms == "courant") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['A1', 'A2', 'A3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['A1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['A2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['A3'];
                                            }
                                          }

                                          if (selectedEntregistrement.rms == "cosphi") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['PF1', 'PF2', 'PF3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['PF1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['PF2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['PF3'];
                                            }
                                          }
                                          if (selectedEntregistrement.rms == "puissanceR") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['PR1', 'PR2', 'PR3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['PR1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['PR2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['PR3'];
                                            }
                                          }
                
                                          if (selectedEntregistrement.rms == "puissanceA") {
                                            if (selectedEntregistrement.lignes == "all") {
                                              var power = ['PA1', 'PA2', 'PA3'];
                                            }
                                            if (selectedEntregistrement.lignes == "l1") {
                                              var power = ['PA1'];
                                            }
                                            if (selectedEntregistrement.lignes == "l2") {
                                              var power = ['PA2'];
                                            }
                                            if (selectedEntregistrement.lignes == "l3") {
                                              var power = ['PA3'];
                                            }
                                          }

                                        if (config.dataPointIndex == -1) {
                                            config.dataPointIndex = 0;
                                        }
                                        // var color = ["blue", "green", "red", "yellow"];
                                        var all_data = [];
                                        config.config.series.forEach((element, i) => {
                                            var data = [];
                                            data.push(power[i]);
                                            data.push(element.data[config.dataPointIndex]);
                                            data.push(config.config.xaxis.categories[config.dataPointIndex]);
                                            all_data.push(data)
                                        });
                                   
                                        setArray(all_data)

                                    },
                                },
                            },
                            xaxis: {
                                type: "datetime",
                                categories: chartTimes,
                            },
                            tooltip: {
                                theme: "dark",
                                x: {
                                    format: "dd/MM/yy HH:mm",
                                },
                            },
                            
                            stroke: {
                                curve: "smooth",
                                width: 2,
                            },
                            
                            
                             
                        }}
                        series={lineChartData}
                        type="line"
                        height={400}
                    />
                </div>
            </>
        );
    };
    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="rapports" />
                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title">Rapports & Analyse</h1>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header border-bottom">
                                        <h3 className="card-title">Rapport énergétique</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <label className="form-label col-md-2 col-lg-2 col-xl-1">
                                                Machines
                                            </label>
                                            <div className="form-group col-md-10 col-lg-10 col-xl-4">
                                                <SelectBox
                                                    options={availableOptions}
                                                    value={selectedValues}
                                                    defaultValue={selectedValues}
                                                    onChange={handleChange}
                                                    placeholder={"Selectionnez machine(s)..."}
                                                    menuPlacement="auto"
                                                    menuPosition="fixed"
                                                    autosize={true}
                                                />
                                            </div>

                                            <label className="form-label col-md-2 col-lg-2 col-xl-1">
                                                Date
                                            </label>
                                            <div className="col-md-4 col-lg-4 col-xl-2 ">
                                                <DatePicker
                                                    id={"dateDebut"}
                                                    value={selectedDate}
                                                    onChange={handleDateChange}
                                                />
                                            </div>

                                            <div className="col-md-4 col-lg-4 col-xl-2">
                                                <a
                                                    className="btn btn-outline-green btn-block"
                                                    href={`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/export_energy_to_excel/${selectedVal}/${selected.annee}/${selected.mois}/${selected.jour}/${selected.annee}/${selected.mois}/${selected.jour}`}
                                                >
                                                    <i className="fa fa-file-excel-o me-2"></i>Export XLSX
                                                </a>
                                            </div>
                                            <div className="col-md-4 col-lg-4 col-xl-2">
                                                <a
                                                    className="btn btn-outline-danger btn-block"
                                                    href={`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/export_energy_to_pdf/${selectedVal}/${selected.annee}/${selected.mois}/${selected.jour}/${selected.annee}/${selected.mois}/${selected.jour}`}
                                                >
                                                    <i className="fa fa-file-pdf-o me-2"></i>Export PDF
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-sm">
                                    <div className="col-lg-12">
                                        <div className="card custom-card">
                                            <div className="card-header border-bottom">
                                                <h3 className="card-title">Analyse énergétique</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <label className="form-label col-md-1 col-lg-1 col-xl-1">
                                                        Machine
                                                    </label>
                                                    <div className="form-group col-md-3 col-lg-3 col-xl-3">
                                                        <SelectOne
                                                            placeholder={"Sélectionnez machine ..."}
                                                            options={machinesList.map((element) => ({
                                                                value: element.machine_id,
                                                                label: element.machine_name,
                                                            }))}
                                                            onChange={(e) => handleTypesChange(e, "machine")}
                                                            menuPlacement="auto"
                                                            menuPosition="fixed"
                                                            autosize={true}
                                                        />
                                                    </div>

                                                    <label className="form-label col-md-1 col-lg-1 col-xl-1">
                                                        RMS
                                                    </label>
                                                    <div className="form-group col-md-3 col-lg-3 col-xl-3">
                                                        <SelectOne
                                                             placeholder={"Sélectionnez RMS ..."}
                                                             options={
                                                               rms.map(element => (
                                                                 { value: element.value, label: element.label }
                                                               )
                                                               )}
                                                             onChange={(e) => handleTypesChange(e, 'rms')}
                                                             menuPlacement="auto"
                                                             menuPosition="fixed"
                                                             autosize={true}
                                                        />
                                                    </div>
                                                    <label className="form-label col-md-1 col-lg-1 col-xl-1">
                                                        Ligne
                                                    </label>
                                                    <div className="form-group col-md-3 col-lg-3 col-xl-3">
                                                        <SelectOne
                                                            placeholder={"Sélectionnez ligne ..."}
                                                            options={lignes.map((element) => ({
                                                                value: element.value,
                                                                label: element.label,
                                                            }))}
                                                            onChange={(e) => handleTypesChange(e, "lignes")}
                                                            menuPlacement="auto"
                                                            menuPosition="fixed"
                                                            autosize={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <label className="form-label col-md-2 col-lg-2 col-xl-1">
                                                        Date De
                                                    </label>
                                                    <div className="col-md-3 col-lg-3 col-xl-2 ">
                                                        <DatePicker
                                                            id={"dateEnregistrementDebut"}
                                                            value={dateEnregistrement.debut}
                                                            onChange={(e) => handleDateEntregistrementChange(e, 'debut')}
                                                        />
                                                    </div>
                                                    <label className="form-label col-md-2 col-lg-2 col-xl-1">
                                                        A
                                                    </label>
                                                    <div className="col-md-3 col-lg-3 col-xl-2 ">
                                                        <DatePicker
                                                            id={"dateEnregistrementFin"}
                                                            value={dateEnregistrement.fin}
                                                            onChange={(e) => handleDateEntregistrementChange(e, 'fin')}
                                                        />
                                                    </div>
                                                    <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                                                        De
                                                    </label>
                                                    <div className="col-md-3 col-lg-3 col-xl-2 ">
                                                        <TimePicker
                                                            id={"timeDebut"}
                                                            value={selectedTime.timeDebut}
                                                            onChange={(e) => handleTimeChange(e, "timeDebut")}
                                                        />
                                                    </div>

                                                    <label className="form-label-A col-md-3 col-lg-3 col-xl-1">
                                                        A
                                                    </label>
                                                    <div className="col-md-3 col-lg-3 col-xl-2 ">
                                                        <TimePicker
                                                            id={"timeFin"}
                                                            value={selectedTime.timeFin}
                                                            onChange={(e) => handleTimeChange(e, "timeFin")}
                                                        />
                                                    </div>
                                                  
                                                </div>
                                                <div className="btn-list text-center mt-5">
                                                    <button
                                                        className="btn btn-outline-info"
                                                        type="submit"
                                                        onClick={(e) => ChercherSubmit()}
                                                    >
                                                        <i className="fa fa-search me-2"></i>Chercher
                                                    </button>
                                                    <ToastContainer
                                                        className="toast-container"
                                                        autoClose={4000}
                                                        closeButton={false}
                                                        theme={"colored"}
                                                        icon={true}
                                                        />
                                                    <a
                                                        className="btn btn-outline-green"
                                                        href={
                                                            selectedEntregistrement.timeDebut == ""
                                                                ? `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/enregistrement_history/${selectedEntregistrement.machine}/${selectedEntregistrement.rms}/${selectedEntregistrement.lignes}/${selectedEntregistrement.debut}/${selectedEntregistrement.fin}`
                                                                : `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/rapport/enregistrement_time/${selectedEntregistrement.machine}/${selectedEntregistrement.rms}/${selectedEntregistrement.lignes}/${selectedEntregistrement.debut}/${selectedEntregistrement.timeDebut}/${selectedEntregistrement.fin}/${selectedEntregistrement.timeFin}`
                                                        }
                                                    >
                                                        <i className="fa fa-file-excel-o me-2"></i>Export XLSX
                                                    </a>
                                                </div>
                                                {showCard ? <ChartCard /> : <></>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default Rapports;
