
// import './App.css';
import * as React from 'react';
import {BrowserRouter as Router, Routes, Route , Navigate } from 'react-router-dom';
import SuiviTempsReel from './pages/SuiviTempsReel';
import Historique from './pages/Historique';
import BilanEnergie from './pages/BilanEnergie';
import Facture from './pages/Facture';
import Rapports from './pages/Rapports';
import Alertes from './pages/Alertes';
import Archives from './pages/Archives';
import ParcMachines from './pages/ParcMachines';
import Login from './pages/Login';
import Register from './pages/Register';
import Pression from './pages/Pression';
import ParametresFacture from './pages/ParametresFacture';
import EnergieCumulee from './pages/EnergieCumulee';
import ParamsApp from './pages/ParamsApp';
import GestionMachines from './pages/GestionMachines';
import EditMachine from './pages/EditMachine';
import Faq from './pages/Faq';
import GestionUtilisateur from './pages/GestionUtilisateur';
import AddUser from './pages/AddUser';
import GestionUsine from './pages/GestionUsine';
import EmissionCo from './pages/EmissionCo';
import ResetPasswordEmail from "./pages/ResetPasswordEmail";
import CheckEmail from './pages/CheckEmail';
import Verification from './pages/Verification';
import ResetPassConfirm from './pages/ResetPassConfirm';
import VerifyEmail from './pages/VerifyEmail';
import GestionCompresseur from './pages/GestionCompresseur';
import EditCompresseur from './pages/EditCompresseur';


function App() {
  const isAuthenticated = localStorage.getItem('jwt');
  return (
    <>
  
    <Router>
      <Routes>
      
        <Route  exact path='/' element={ isAuthenticated !== null ? <SuiviTempsReel /> : <Navigate to="/login" />}/>
        <Route  exact path='/Dashboard' element={ <SuiviTempsReel />}/>
        <Route  path='/historique' element={<Historique/>}/>
        <Route  path='/login' element={<Login/>}/>
        <Route  path='/bilan_energie' element={<BilanEnergie/>}/>
        <Route  path='/facture' element={<Facture/>}/>
        <Route  path='/rapports' element={<Rapports/>}/>
        <Route  path='/alertes' element={<Alertes/>}/>
        <Route  path='/archives' element={<Archives/>}/>
        <Route  path='/parc_machine' element={<ParcMachines/>}/>
        <Route  path='/pression' element={<Pression/>}/>
        <Route  path='/energyCumule' element={<EnergieCumulee/>}/>
        <Route  path='/paramsFact' element={<ParametresFacture/>}/>
        <Route  path='/gestionMachines' element={<GestionMachines/>}/>
        <Route  path='/GestionUsine' element={<GestionUsine/>}/>
        <Route  path='/gestionUtilisateurs' element={<GestionUtilisateur/>}/>
        <Route  path='/ajoutUtilisateur' element={<AddUser/>}/>
        <Route  path='/faq' element={<Faq/>}/>
        <Route  path='/emissionCo2' element={<EmissionCo/>}/>
        <Route  path='/editMachine/:id' element={<EditMachine/>}/>
        <Route  path='/admin/inscription' element={<Register/>}/>
        <Route  path='/admin/params' element={<ParamsApp/>}/>
        <Route  path='/resetPassword' element={<ResetPasswordEmail/>}/>
        <Route path="/check-email" element={< CheckEmail/>} />
        <Route path="/resetpassConfirm/:token" element={< ResetPassConfirm/>} />  
        <Route path="/verify-email" element={< VerifyEmail/>} />
        <Route path="/verification/:token" element={< Verification/>} />    
        <Route path="/GestionCompresseur" element={< GestionCompresseur/>} />
        <Route  path='/editCompresseur/:id' element={<EditCompresseur/>}/>

      </Routes>
    </Router>
  
 
  </>
  );
}

export default App;
