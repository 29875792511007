import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function EditMachine() {
  const params = useParams()
  const navigate = useNavigate();
  const [data, setData] = useState({
    testImage: '',
  });

  const {
    testImage,
  } = data



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file.type === "image/jpeg" || file.type === "image/png") {
      setData((prevState) => {
        return {
          ...prevState,
          testImage: file,
        }
      })
    } else {
      toast.warning("Seulement les images de types JPEG ou PNG sont autorisées");
    }
  }

  
  const handleEditSubmit = async () => {
    const formData = new FormData()

      formData.append('testImage', testImage)

      try {
        await axios.post(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/users/editUsine'
          , formData, {
        }).then(res => {
          if (res.status == 200 && res.data.status == "LOGO est mise a jour") {
            toast.success(res.data.status)
            window.location.reload();
          }


          if (res.status == 500 && res.data.message == "Error updating usine") {
            toast.error(res.data.status)
          }
        })

      } catch (error) {
        console.log(error)
        toast.error(error.response.data)
      }
    // }
  }
  const _arrayBufferToBase64 = ( buffer ) => {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  const [usineData, setUsineData] = useState([]);
  useEffect(() => {
    async function fetchparams() {
      let data;
      try {
        data = await axios.get(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/users/usine/gestionUsine'
        )
        setUsineData(data.data.usine);

      } catch (error) {
        console.log(error)
      }
    }
    fetchparams();
  }, [])
  // console.log("DATAAAAAAAA  ",usineData);
  // console.log("contentType  ",usineData.img.data);

  return (
    <>
    {}
      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Modifier LOGO usine </h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">      
                          <label className="form-label  col-xl-3"> Image </label>
                          <div className="col-md-3 col-lg-3 col-xl-3">
                          { usineData?.img?.data?.data ?
                           <img
                            src={`data:${usineData.img.contentType};base64,${_arrayBufferToBase64(usineData.img.data.data)}`}
                            alt=""
                            className="logo-img"
                          />  : <img alt ="" />
                          }
                          </div>
                          <div className="form-group col-md-4 col-lg-4 col-xl-4">
                            <input type="file" onChange={handleFileChange} accept=".jpg, .jpeg, .png" />
                          </div>
                          <div className="col-md-2 col-lg-2 col-xl-2">
                          <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                            <button className="btn btn-outline-success btn-block" type="submit" onClick={handleEditSubmit}>
                              Valider
                            </button>
                            <ToastContainer
                              className="toast-container"
                              autoClose={2000}
                              closeButton={false}
                              theme={"colored"}
                              icon={true}
                            />
                          </div>
                        </div>
                        </div>

                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default EditMachine;
