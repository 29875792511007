import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import EnercoInput from "../components/EnercoInput.js";
import PasswordInput from '../components/PasswordInput';


function Login() {
  const [values, setValues] = useState({
    email: "",
    password: "",
    remember: localStorage.getItem('rememberToken') ? true : false,
  });

  const navigate = useNavigate();

  const generateError = (error) =>
    toast.error(error, {
      position: "top-right",
    });

  useEffect(() => {
    if (!values.remember) {
      localStorage.removeItem('rememberToken');
    }
  }, [values.remember]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setValues({
      ...values,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/login/${values.email}/${values.password}`,
        { withCredentials: true }
      );

      if (data) {
        if (data.errors) {
          const { email, password } = data.errors;
          if (email) generateError(email);
          else if (password) generateError(password);
        } else {
          try {
            if (values.remember) {
              localStorage.setItem('rememberToken', 'true');

            }
            localStorage.setItem('jwt', data.token);
            localStorage.setItem('machines', JSON.stringify(data.machines));
            localStorage.setItem("userRole", data.role);
            localStorage.setItem("username", data.username);
            navigate("/Dashboard");
          } catch (error) {
            console.error("Error during navigation:", error);
          }
        }
      }
    } catch (error) {
      if (error.isAxiosError && !error.response) {
        generateError("Network error occurred. Please check your network connection.");
      } else {
        console.log(error);
        generateError("An error occurred during login. Please try again.");
      }
    }
  };

  return (
    <div className="page-register">
      <div className="container-login">
        <div className="wrap-login pl-3 pr-3">
          <div className="container">
            <div className="login-pic">
              <img src="/assets/images/login/logo-enerco2.png" alt="logo" />
            </div>
          </div>

          <form className="register-form validate-form p-2" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <EnercoInput
                  label="Email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <PasswordInput
                  label="Mot de passe"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="validate-input">
              <div className="row">
                <div className="col-md-6 login-checkbox-container">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    checked={values.remember}
                    onChange={handleChange}
                  />
                  <label className="login-checkbox" htmlFor="remember">Remember Me</label>
                </div>
                <div className="col-md-6 login-checkbox-container">
                  <div className="forgot-pass">
                    <a href="/resetPassword">Mot de passe oublié?</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-register-form-btn">
              <button type="submit" className="register-form-btn">Se Connecter</button>
              <ToastContainer
                className="toast-container"
                autoClose={2000}
                closeButton={false}
                theme={"colored"}
                icon={true}
              />
            </div>
          </form>
          <div className="social-icons">
            <a href="https://www.facebook.com/elctrosoft">
              <img src="/assets/images/login/facebook.png" alt="Facebook" />
            </a>
            <a href="http://enerco.electrosoft.tn:3000">
              <img src="/assets/images/login/apk.png" alt="apk" />
            </a>
            <a href="https://electrosoft.com.tn">
              <img src="/assets/images/login/site.png" alt="Google" />
            </a>
          </div>
          <div className="create-account">
            <span> Vous n'avez pas de compte ? </span><a href="/admin/inscription">S'inscrire</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
