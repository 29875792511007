import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import axios from 'axios';
import { io } from 'socket.io-client';


function EnergieCumulee() {
    var day = 0;
    var month = 0;
    var year = 0;
    const machine = JSON.parse(localStorage.getItem('machines'))
    const { current: Machines } = useRef(machine);
    const [defaultMachinesData, setDefaultMachinesData] = useState([]);
    const [listMachine, setListMachine] = useState([])
    const [dateReturn, setDateReturn] = useState(null)

    const setMachineData = async () => { 
        let dataEnergieCumulee;
        try {
            dataEnergieCumulee = await axios.get(
                 process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/puissance/getDataPuissanceCumulee')
        } catch (error) {
            console.log(error)
        }

        if(Object.keys(dataEnergieCumulee.data).length == 0){
            var all_data = [];
            if (Object.keys(Machines).length > 0) {
                Machines.forEach(element => {
                    var data = {
                        machine_id: element.machine_id,
                        energie: 0,
                        carbone : 0,
                      }
                      
                      all_data.push(data);
                })

                localStorage.setItem('datas_puissance_cumulee', JSON.stringify(all_data))
            }
        }else{
            var all_data = [];
            
            if (Object.keys(Machines).length > 0) {
                Machines.forEach(element => {
                    const energie_data = dataEnergieCumulee.data;
                    var data = {};
                    var check = false;
                    energie_data.forEach(energie_machines => {
                        if(energie_machines.id_machine == element.machine_id){
                            check = true;
                            data['machine_id'] = element.machine_id;
                            data['energie'] = Math.round((energie_machines.valeur) * 100) / 100;
                            data['carbone'] = Math.round((energie_machines.carbone) * 10) / 10;
                            data['machine_name'] = element.machine_name;
                        }
                    })
                    if(check == true){
                        all_data.push(data);
                    }else{
                        data['machine_id'] = element.machine_id;
                        data['energie'] = 0;
                        data['carbone'] = 0;
                        data['machine_name'] = element.machine_name;
                        all_data.push(data);
                    }
                    
                })

                localStorage.setItem('datas_puissance_cumulee', JSON.stringify(all_data))
            }
        }
        var dateNow = new Date();
        day = dateNow.getDate();
        month = dateNow.getMonth() + 1;
        year = dateNow.getFullYear();

        var full_date = day + '-' + month + "-" + year;

        setDateReturn(full_date)
        
    }

    useEffect(() => {
        setListMachine(Machines);
        const storedData = JSON.parse(localStorage.getItem('datas_puissance_cumulee'));
        if (storedData) {
        	 setDefaultMachinesData(storedData);
        	 setMachineData();
        }
        else {
        	setMachineData();
        	
        }
      
       
    }, []);

    const getDataFromSocket = async (data) => { 
        let dataEnergieCumulee;
        try {
            dataEnergieCumulee = await axios.get(
                process.env.REACT_APP_BACKEND_IP
                + ':' + process.env.REACT_APP_BACKEND_PORT
                + '/puissance/getDataPuissanceCumuleeById/' + data.machine.id_machine)
        } catch (error) {
            console.log(error)
        }

        var new_data = JSON.parse(localStorage.getItem('datas_puissance_cumulee'));
            new_data.forEach((machine) => {
                if (machine.machine_id === data.machine.id_machine) {
                    var incomming_energy = data.power.power / 360000; 
                    var incomming_carbone = (incomming_energy * 0.283 ) * 2.394    
                    machine.energie = dataEnergieCumulee.data.valeur + incomming_energy;
                    machine.carbone = dataEnergieCumulee.data.carbone + incomming_carbone;
                    
                }
            })
    
        localStorage.setItem('datas_puissance_cumulee', JSON.stringify(new_data))
        setDefaultMachinesData(new_data)
        var dateNow = new Date();
        day = dateNow.getDate();
        month = dateNow.getMonth() + 1;
        year = dateNow.getFullYear();

        var full_date = day + '-' + month + "-" + year;

        setDateReturn(full_date)
    }

    useEffect(() => {
      
            const socket = io(`${process.env.REACT_APP_BACKEND_IP}:${process.env.REACT_APP_BACKEND_PORT}/`);
            socket.on("data", (data) => {
            if(!data.hasOwnProperty('pression')){
                getDataFromSocket(data)
                
            }
            });
            return () => {
                socket.disconnect();
              };
      
      }, []);
      const objectKeys = Object.keys(defaultMachinesData);
    return (
        <>
            <div className="page">
                <div className="page-main">
                    <Header />
                    <Sidebar activeItem="energyCumule" />

                    <div className="app-content main-content mt-20">
                        <div className="side-app">
                            <div className="main-container container-fluid">
                                <div className="page-header">
                                    <div>
                                        <h1 className="page-title"> {dateReturn ? "Energie & CO₂ cumulée pour le : " + dateReturn : "Flux de données interrompus !"}  </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <div className='row'>
                                {
                                    (defaultMachinesData !== null && defaultMachinesData !== undefined) ?
                                   
                                    objectKeys.map((index) => (
                                        <div className="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h3 className="mb-2 fw-semibold text-card-energy">{defaultMachinesData[index].machine_name}</h3>
                                                    <h5 className="mb-2 fw-semibold text-card-energy mt-5 color_energy_cumulee">{Math.round((defaultMachinesData[index].energie) * 100) / 100} Kwh</h5>
                                                    <h5 className="mb-2 fw-semibold text-card-energy mt-5 color_co2">{Math.round((defaultMachinesData[index].carbone) * 10) / 10} KgCO₂</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )) :  <div className="card"><div className="card-body"> Aucun enregistrement disponible </div></div>
                                } 
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
export default EnergieCumulee
