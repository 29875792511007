import React, { useState, useEffect} from "react";
import { io } from 'socket.io-client';

const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT



function CardsTpsReel(props) {
    const [PT, setPT] = useState(() => {
        const storedPT = localStorage.getItem('pt');
        return storedPT !== null ? JSON.parse(storedPT) : 0;
    })
    const [PAT, setPAT] = useState(() => {
        const storedPAT = localStorage.getItem('pat');
        return storedPAT !== null ? JSON.parse(storedPAT) : 0;
    })
    const [PRT, setPRT] = useState(() => {
        const storedPRT = localStorage.getItem('prt');
        return storedPRT !== null ? JSON.parse(storedPRT) : 0;
    })
    const [PFmoy, setPFmoy] = useState(() => {
        const storedPFmoy = localStorage.getItem('pfmoy');
        return storedPFmoy !== null ? JSON.parse(storedPFmoy) : 0;
    })
    const [time, setTime] = useState(() => {
        const storedTime = localStorage.getItem('ptime');
        return storedTime !== null ? JSON.parse(storedTime) : "--:--:--";
    })

    useEffect(() => {
        localStorage.setItem('pt' , JSON.stringify(PT))
        localStorage.setItem('pat' , JSON.stringify(PAT))
        localStorage.setItem('prt' , JSON.stringify(PRT))
        localStorage.setItem('pfmoy' , JSON.stringify(PFmoy))
        localStorage.setItem('ptime' , JSON.stringify(time))
    }, [PT,PAT,PRT,PFmoy,time])  
    useEffect(() => {
        const socket = io(ENDPOINT);
        socket.connect();
        socket.on("data", (data) => {
           
            const selectedMachine = localStorage.getItem('machine_selected')
            var date = new Date(data.time.time)
            var hour = date.getHours() - 1;
            var minute = date.getMinutes();
            var second = date.getSeconds();

            if (hour < 10) {
                hour = "0" + hour;
              }
              if (minute < 10) {
                minute = "0" + minute;
              }
              if (second < 10) {
                second = "0" + second;
              }
            if (selectedMachine !== undefined){
                if (data.machine && data.machine.id_machine && data.machine.id_machine === selectedMachine ){
                  
                    setPT((data.power.power1 + data.power.power2 + data.power.power3) / 1000);
                    setPAT((data.powerA.powerA1 + data.powerA.powerA2 + data.powerA.powerA3) / 1000);
                    setPRT((data.powerR.powerR1 + data.powerR.powerR2 + data.powerR.powerR3) / 1000)
                    setPFmoy((data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3);
                    setTime(hour + ':' + minute + ':' + second)
                   
                }
            }
            return () => {
                socket.disconnect();
              }
        })
    }, []);

   
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb-2 fw-semibold">Puissance Totale</h4>
                                    <p className="text-muted fs-13 mb-0">{Math.round(PT * 100)/100} KW</p>
                                    <p className="text-muted mb-0 mt-2 fs-12">
                                        <span className="icn-box text-success fw-semibold fs-13 me-1 mr-5">
                                            <i className="fa fa-clock-o" />
                                        </span>
                                        {time}
                                    </p>
                                </div>
                                <div className="col col-auto top-icn dash">
                                    <div className="counter-icon ms-auto">
                                    <img
                                            src={process.env.REACT_APP_BASE_URL + "/assets/images/cards/powerT.png"}
                                            alt= ""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb-2 fw-semibold">P. Apparente Totale</h4>
                                    <p className="text-muted fs-13 mb-0">{Math.round(PAT * 100)/100} KVA</p>
                                    <p className="text-muted mb-0 mt-2 fs-12">
                                        <span className="icn-box text-success fw-semibold fs-13 me-1 mr-5">
                                            <i className="fa fa-clock-o" />
                                        </span>
                                        {time}
                                    </p>
                                </div>
                                <div className="col col-auto top-icn dash">
                                <div className="counter-icon ms-auto">
                                    <img
                                            src={process.env.REACT_APP_BASE_URL + "/assets/images/cards/powerA.png"}
                                            alt= ""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb-2 fw-semibold">P. Réactive Totale</h4>
                                    <p className="text-muted fs-13 mb-0">{Math.round(PRT * 100)/100} KVAR</p>
                                    <p className="text-muted mb-0 mt-2 fs-12">
                                        <span className="icn-box text-success fw-semibold fs-13 me-1 mr-5">
                                            <i className="fa fa-clock-o" />
                                        </span>
                                        {time}
                                    </p>
                                </div>
                                <div className="col col-auto top-icn dash">
                                <div className="counter-icon ms-auto">
                                    <img
                                            src={process.env.REACT_APP_BASE_URL + "/assets/images/cards/powerR.png"}
                                            alt= ""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                    <div className="card overflow-hidden">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4 className="mb-2 fw-semibold">PF Moyen</h4>
                                    <p className="text-muted fs-13 mb-0">{Math.round(PFmoy * 100)/100}</p>
                                    <p className="text-muted mb-0 mt-2 fs-12">
                                        <span className="icn-box text-success fw-semibold fs-13 me-1 mr-5">
                                            <i className="fa fa-clock-o" />
                                        </span>
                                        {time}
                                    </p>
                                </div>
                                <div className="col col-auto top-icn dash">
                                    <div className="counter-icon ms-auto">
                                    <img
                                            src={process.env.REACT_APP_BASE_URL + "/assets/images/cards/cosphi.png"}
                                            alt= ""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CardsTpsReel
