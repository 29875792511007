import React, { useState, useEffect, useRef } from "react";
import { io} from 'socket.io-client';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
const ENDPOINT = process.env.REACT_APP_BACKEND_IP + ":" + process.env.REACT_APP_BACKEND_PORT



function ResponsiveDataTable() {
  const [listMachine, setListMachine] = useState([])
  const machine = JSON.parse(localStorage.getItem('machines'))
  const { current: Machines } = useRef(machine);
  const [defaultMachinesData, setDefaultMachinesData] = useState([]);

  const setMachineData = () => {
    if (localStorage.getItem('datas_machines') === null) {
      var all_data = [];
      if (Object.keys(Machines).length > 0) {
        Machines.forEach(element => {
          var data = {
            machine_id: element.machine_id,
            machine_name: element.machine_name,
            etat: -1,
            VL1: 0,
            VL2: 0,
            VL3: 0,
            U12: 0,
            U23: 0,
            U31: 0,
            AL1: 0,
            AL2: 0,
            AL3: 0,
            PFL1: 0,
            PFL2: 0,
            PFL3: 0,
            PL1: 0,
            PL2: 0,
            PL3: 0,
            PT: 0,
            PFM: 0,
          }
          all_data.push(data);
        });
      }
      localStorage.setItem('datas_machines', JSON.stringify(all_data))
    }

  }



  useEffect(() => {
    setListMachine(Machines);
    setMachineData();
    setDefaultMachinesData(JSON.parse(localStorage.getItem('datas_machines')));

  }, [listMachine]);

  useEffect(() => {

    const socket = io(ENDPOINT);
    socket.connect();
    socket.on("data", (data) => {
      if (data.cosphi && data.cosphi.cosphi1 && data.cosphi.cosphi2 && data.cosphi.cosphi3) {
        setDefaultMachinesData(JSON.parse(localStorage.getItem('datas_machines')));
        let moy_cosphi = ((data.cosphi.cosphi1) + (data.cosphi.cosphi2) + (data.cosphi.cosphi3)) / 3
   
        let etat ;
          if (moy_cosphi < 0.3) {
            etat = 0;
          } 
          else if (moy_cosphi >= 0.3 && moy_cosphi <= 1) {
            etat = 1
          }
          else {
            etat = -1
          }

          var new_data = JSON.parse(localStorage.getItem('datas_machines'));
          if (new_data !== null){
              new_data.forEach((machine) => {
                  if (machine.machine_id === data.machine.id_machine) {
                    if(data.machine.id_machine == 'u1d1m1' ){
                      const ondMTData = new_data.find(machine => machine.machine_id === 'OND-MT');
                      const departData = new_data.find(machine => machine.machine_id === 'PV-DEP-USINE');
                      if (departData && ondMTData && departData.PT < ondMTData.PT){
                        machine.PL1 = "0";
                        machine.PL2 = "0";
                        machine.PL3 = "0";
                        machine.PT = "0";
                      }else{
                        machine.PL1 = data.power.power1;
                        machine.PL2 = data.power.power2;
                        machine.PL3 = data.power.power3;
                        machine.PT = data.power.power;
                      }
                      machine.etat = etat;
                        machine.VL1 = data.voltage.voltage1;
                        machine.VL2 = data.voltage.voltage2;
                        machine.VL3 = data.voltage.voltage3;
                        machine.U12 = data.voltageC.voltageC1;
                        machine.U23 = data.voltageC.voltageC2;
                        machine.U31 = data.voltageC.voltageC3;
                        machine.AL1 = data.current.current1;
                        machine.AL2 = data.current.current2;
                        machine.AL3 = data.current.current3;
                        machine.PFL1 = data.cosphi.cosphi1;
                        machine.PFL2 = data.cosphi.cosphi2;
                        machine.PFL3 = data.cosphi.cosphi3;
                        machine.PFM = (data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3;
                    }else{
                      machine.etat = etat;
                      machine.VL1 = data.voltage.voltage1;
                      machine.VL2 = data.voltage.voltage2;
                      machine.VL3 = data.voltage.voltage3;
                      machine.U12 = data.voltageC.voltageC1;
                      machine.U23 = data.voltageC.voltageC2;
                      machine.U31 = data.voltageC.voltageC3;
                      machine.AL1 = data.current.current1;
                      machine.AL2 = data.current.current2;
                      machine.AL3 = data.current.current3;
                      machine.PFL1 = data.cosphi.cosphi1;
                      machine.PFL2 = data.cosphi.cosphi2;
                      machine.PFL3 = data.cosphi.cosphi3;
                      machine.PL1 = data.power.power1;
                      machine.PL2 = data.power.power2;
                      machine.PL3 = data.power.power3;
                      machine.PT = data.power.power;
                      machine.PFM = (data.cosphi.cosphi1 + data.cosphi.cosphi2 + data.cosphi.cosphi3) / 3;
                    }
                      
                    }
                })
            }
            localStorage.setItem('datas_machines', JSON.stringify(new_data))
            setDefaultMachinesData(new_data);
            return () => {
              socket.disconnect();
            }
      }
    })
    
   
  }, []);

  useEffect (() => {
    const socket = io(ENDPOINT);
    if (socket.connected ===  false) {
      localStorage.removeItem('datas_machines')
    }
  },[] )
  const bodyTable = (data) => {
  if(data != null){
  if (Object.keys(data).length > 0) {
      return (
        Object.keys(data).map(key => (
        
          <tr key={data[key]['machine_id']}>
            <td style={{ maxWidth: "130px", whiteSpace: "pre-wrap"}}>{data[key]['machine_name']}</td>
            <td>{(data[key]['etat'] == -1) ? 
                <div className="wrem-1 h-4 bg-grey br-100"></div> : 
                ((data[key]['etat'] == 1) ? <div className="wrem-1 h-4 bg-green br-100"></div>:<div className="wrem-1 h-4 bg-danger br-100"></div>)} </td>
                  <td>{Math.round(data[key]['PT'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PFM'] * 100)/100}</td>
                  <td>{Math.round(data[key]['VL1'] * 100)/100}</td>
                  <td>{Math.round(data[key]['VL2'] * 100)/100}</td>
                  <td>{Math.round(data[key]['VL3'] * 100)/100}</td>
                  <td>{Math.round(data[key]['U12'] * 100)/100}</td>
                  <td>{Math.round(data[key]['U23'] * 100)/100}</td>
                  <td>{Math.round(data[key]['U31'] * 100)/100}</td>
                  <td>{Math.round(data[key]['AL1'] * 100)/100}</td>
                  <td>{Math.round(data[key]['AL2'] * 100)/100}</td>
                  <td>{Math.round(data[key]['AL3'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PFL1'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PFL2'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PFL3'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PL1'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PL2'] * 100)/100}</td>
                  <td>{Math.round(data[key]['PL3'] * 100)/100}</td>
               
          </tr>
        ))
      )
    }
  }
    
  };
  return (
    <>
   
      <div className="row row-sm">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header border-bottom">
              <h3 className="card-title">Parc Machines</h3>
            </div>
            <div className="card-body">
              
                <div className="table-responsive">
                  <table className="table table-striped table-bordered text-nowrap border-bottom w-100">
                    <thead>
                      <tr>
                        <th className="wd-10p border-bottom-0">Machine</th>
                        <th className="wd-15p border-bottom-0">Etat</th>
                        <th className="wd-25p border-bottom-0">PT (W)</th>
                        <th className="wd-25p border-bottom-0">PFM</th>
                        <th className="wd-20p border-bottom-0">VL1 (V)</th>
                        <th className="wd-15p border-bottom-0">VL2 (V)</th>
                        <th className="wd-10p border-bottom-0">VL3 (V)</th>
                        <th className="wd-10p border-bottom-0">U12 (V)</th>
                        <th className="wd-10p border-bottom-0">U23 (V)</th>
                        <th className="wd-10p border-bottom-0">U31 (V)</th>
                        <th className="wd-25p border-bottom-0">AL1 (A)</th>
                        <th className="wd-25p border-bottom-0">AL2 (A)</th>
                        <th className="wd-25p border-bottom-0">AL3 (A)</th>
                        <th className="wd-25p border-bottom-0">PFL1</th>
                        <th className="wd-25p border-bottom-0">PFL2</th>
                        <th className="wd-25p border-bottom-0">PFL3</th>
                        <th className="wd-25p border-bottom-0">PL1 (W)</th>
                        <th className="wd-25p border-bottom-0">PL2 (W)</th>
                        <th className="wd-25p border-bottom-0">PL3 (W)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bodyTable(defaultMachinesData)}
                    </tbody>
                  </table>
                </div>
                
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResponsiveDataTable
