import React, { useState, useEffect } from "react";
import LazyLoad from 'react-lazyload';
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";


function GestionMachines() {
  const [machineData, setMachineData] = useState([])


  useEffect(() => {
    async function fetchData() {
      let dataMachine;
      try {
        dataMachine = await axios.get(
          process.env.REACT_APP_BACKEND_IP
          + ':' + process.env.REACT_APP_BACKEND_PORT
          + '/machines/getParamsMachines')
        setMachineData(dataMachine.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
    
  }, [])

  const _arrayBufferToBase64 = ( buffer ) => {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }


  function tableBody(data) {
    if (Object.keys(data).length > 0) {
      return (
        <table
          id="basic-datatable"
          className="table table-bordered table-vcenter text-nowrap mb-0 w-100"
        >
          <thead>
            <tr>
              <th className="w-14">Images</th>
              <th className="border-bottom-0">Nom machine</th>
              <th className="border-bottom-0">Tension Max</th>
              <th className="border-bottom-0">Tension Min</th>
              <th className="border-bottom-0">Courant Max</th>
              <th className="border-bottom-0">Courant Min</th>
              <th className="border-bottom-0">N° Responsable</th>
              <th className="border-bottom-0 w-14">Action</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data).map(key => {
              return (
                <tr key={data[key]['id_machine']}>
                {
                data?.[key]?.img?.data?.data ? 
                <img
                src={`data:${data[key]['img'].contentType};base64,${_arrayBufferToBase64(data[key]['img'].data.data)}`}
                alt=""
                className="cart-img"
              />
              : <img alt ="" />
               } 
                  <td>{data[key]['nom']}</td>
                  <td>{data[key]['tensionMaximal']}</td>
                  <td>{data[key]['tensionMinimal']}</td>
                  <td>{data[key]['courantMaximal']}</td>
                  <td>{data[key]['courantMinimal']}</td>
                  <td>{data[key]['numero']}</td>
                  <td>
                    <Link to={"/editMachine/"+ data[key].id_machine}>
                      <button className="btn btn-warning btn-spinners ms-2" type="submit">
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                    </Link>
                  </td>
                </tr>
    
              )
            })
            }
          </tbody>
        </table>
      )
    }
  
  }

  return (
    <>

      <div className="page">
        <div className="page-main">
          <Header />
          <Sidebar activeItem="gestionMachines" />
          <div className="app-content main-content mt-20">
            <div className="side-app">
              <div className="main-container container-fluid">
                <div className="page-header">
                  <div>
                    <h1 className="page-title">Gestion Machines</h1>
                  </div>
                </div>

                <div className="row row-sm">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-header border-bottom">
                        <h3 className="card-title">Listes des machines</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                        <LazyLoad once>
                          {
                            Object.keys(machineData).length > 0 ?
                              (tableBody(machineData))
                              : (<></>)
                          }
                          </LazyLoad>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
export default GestionMachines;
